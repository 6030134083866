var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{style:({ height: '100%', width: '100%' }),attrs:{"loading":_vm.GetListAction,"type":"card-heading, image@3"}},[(_vm.broadcasters.length > 0)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"title-m-bold neutral-900--text",attrs:{"cols":"auto"}},[_vm._v(" Активности клиентов "),(_vm.$route.meta.help)?_c('v-icon',{on:{"click":function($event){return _vm.$router.open(_vm.$route.meta.help)}}},[_vm._v(" mdi-help-circle-outline ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-row',[_c('v-col',[_c('base-text-field',{staticStyle:{"min-width":"225px"},attrs:{"prepend-inner-icon":'$iconify_search-outlined',"placeholder":"Быстрый поиск","escape-clearable":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createBroadcasterClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_plus-circle-outlined ")]),_c('span',[_vm._v("Создать")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('base-table',{attrs:{"headers":_vm.headers,"items":_vm.filtered_broadcasters,"item-key":"id","item-class":function () { return 'clickable-row'; },"is-custom-header":false,"word-operations":['активность', 'активности', 'активностей'],"storage-key":"BroadcasterTable","options":_vm.options,"search":_vm.search_comp,"expanded":_vm.expanded,"show-expand":true},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onBroadcasterClick},scopedSlots:_vm._u([{key:"item.startAtFormat",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.start_at}})]}},{key:"item.finishAtFormat",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.finish_at}})]}},{key:"item.lastEmitFormat",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.last_emit}})]}},{key:"item.nextEmitFormat",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.next_emit}})]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"custom-switch",attrs:{"loading":item.changeActiveAction,"disabled":item.changeActiveAction,"inset":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.activeChange(item, $event)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.action}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getItemActions(item)),function(menuItem){return _c('v-list-item',{key:menuItem.id,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                      'body-s-medium': true,
                      'neutral-500--text': true,
                    }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}},{key:"expanded-item",fn:function(ref){
                    var item = ref.item;
return [_c('td',{staticClass:"text-center",attrs:{"colspan":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('table-handlers',{attrs:{"handlers":item.handlers},on:{"update:handlers":function($event){return _vm.$set(item, "handlers", $event)},"onItemClick":function($event){return _vm.openHandlerClick(item, $event)},"createHandlerClick":function($event){return _vm.createHandlerClick(item)}}})],1)]}}],null,true)})],1)],1)],1):_c('base-empty-block-page',{attrs:{"title":"Здесь пока ничего нет :’(","action-icon":"$iconify_ant-design-gift-outlined","action-text":"Создать активность","action":""},on:{"action":_vm.createBroadcasterClick},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Настройка активностей позволяет автоматически запускать различные действия: добавление в сегмент или удаление из сегмента клиентов, запуск рассылок, обработка нажатия кнопки в рассылке, выпуск карты и прочие ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("@/assets/png/settings-dummy.png"),"width":"186px","height":"156px"}})]},proxy:true}])}),(_vm.dialog)?_c('broadcaster-log-dialog',{attrs:{"broadcaster-id":_vm.selectedId},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),(_vm.editedHandler && _vm.showHandlerDialog)?_c('eventHandlerDialog',{attrs:{"model":_vm.editedHandler,"broadcaster":_vm.editedHandlerBroadcaster},on:{"save":_vm.onSaveHandler,"delete":_vm.onDeleteHandler},model:{value:(_vm.showHandlerDialog),callback:function ($$v) {_vm.showHandlerDialog=$$v},expression:"showHandlerDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }