<template>
  <v-skeleton-loader
    :loading="GetListAction"
    :style="{ height: '100%', width: '100%' }"
    type="card-heading, image@3"
  >
    <v-container
      v-if="broadcasters.length > 0"
      fluid
      class=""
    >
      <v-row
        justify="space-between"
        align="center"
      >
        <v-col
          cols="auto"
          class="title-m-bold neutral-900--text"
        >
          Активности клиентов
          <v-icon
            v-if="$route.meta.help"
            @click="$router.open($route.meta.help)"
          >
            mdi-help-circle-outline
          </v-icon>
        </v-col>
        <!-- <v-spacer /> -->
        <v-col
          cols="6"
          sm="12"
          md="6"
        >
          <v-row>
            <v-col>
              <base-text-field
                v-model.trim="search"
                :prepend-inner-icon="'$iconify_search-outlined'"
                placeholder="Быстрый поиск"
                style="min-width: 225px"
                escape-clearable
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click="createBroadcasterClick"
              >
                <v-icon left>
                  $iconify_plus-circle-outlined
                </v-icon>
                <span>Создать</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <base-table
            :headers="headers"
            :items="filtered_broadcasters"
            item-key="id"
            :item-class="() => 'clickable-row'"
            :is-custom-header="false"
            :word-operations="['активность', 'активности', 'активностей']"
            storage-key="BroadcasterTable"
            :options.sync="options"
            :search="search_comp"
            :expanded.sync="expanded"
            :show-expand="true"
            @click:row="onBroadcasterClick"
          >
            <!-- <v-data-table
            :headers="headers"
            :items="filtered_moderations"
          > -->
            <template v-slot:[`item.startAtFormat`]="{ item }">
              <date-column :value="item.start_at" />
            </template>
            <template v-slot:[`item.finishAtFormat`]="{ item }">
              <date-column :value="item.finish_at" />
            </template>
            <template v-slot:[`item.lastEmitFormat`]="{ item }">
              <date-column :value="item.last_emit" />
            </template>
            <template v-slot:[`item.nextEmitFormat`]="{ item }">
              <date-column :value="item.next_emit" />
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-switch
                v-model="item.active"
                :loading="item.changeActiveAction"
                :disabled="item.changeActiveAction"
                inset
                hide-details
                class="custom-switch"
                @click.stop=""
                @change="activeChange(item, $event)"
              />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    small
                    :loading="item.action"
                    v-on="on"
                  >
                    <v-icon>$iconify_feather-more-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="menuItem in getItemActions(item)"
                    :key="menuItem.id"
                    @click="menuItem.action(item)"
                  >
                    <v-list-item-icon>
                      <v-icon color="neutral-500">
                        {{ menuItem.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title
                      :class="{
                        'body-s-medium': true,
                        'neutral-500--text': true,
                      }"
                    >
                      {{ menuItem.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:expanded-item="{item}">
              <td
                colspan="1"
                class="text-center"
              >
                <v-divider vertical />
              </td>
              <td :colspan="headers.length">
                <table-handlers
                  :handlers.sync="item.handlers"
                  @onItemClick="openHandlerClick(item, $event)"
                  @createHandlerClick="createHandlerClick(item)"
                />
              </td>
            </template>
            <!-- </v-data-table> -->
          </base-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- Заглушка -->
    <base-empty-block-page
      v-else
      title="Здесь пока ничего нет :’("
      action-icon="$iconify_ant-design-gift-outlined"
      action-text="Создать активность"
      action
      @action="createBroadcasterClick"
    >
      <template v-slot:description>
        Настройка активностей позволяет автоматически запускать различные
        действия: добавление в сегмент или удаление из сегмента клиентов, запуск
        рассылок, обработка нажатия кнопки в рассылке, выпуск карты и прочие
      </template>
      <template v-slot:image>
        <v-img
          src="@/assets/png/settings-dummy.png"
          width="186px"
          height="156px"
        />
      </template>
    </base-empty-block-page>
    <broadcaster-log-dialog
      v-if="dialog"
      v-model="dialog"
      :broadcaster-id="selectedId"
    />
    <eventHandlerDialog
      v-if="editedHandler && showHandlerDialog"
      v-model="showHandlerDialog"
      :model="editedHandler"
      :broadcaster="editedHandlerBroadcaster"
      @save="onSaveHandler"
      @delete="onDeleteHandler"
    />
  </v-skeleton-loader>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import Permission from '@/mixins/permission'
  import ProgramEventBroadcaster from '@/models/program/broadcaster'
  import Vue from 'vue'

  export default {
    components: {
      DateColumn: () => import('@/components/colums/DateColumn.vue'),
      BroadcasterLogDialog: () =>
      import('./EventBroadcasterFormTabs/broadcasterLog.vue'),
      tableHandlers: () => import('./components/tableHandlers.vue'),
      eventHandlerDialog: () => import('./components/eventHandlerDialog.vue'),
    },
    mixins: [Permission],
    data () {
      return {
        search: '',
        GetListAction: false,
        selectedId: null,
        dialog: false,
        options: {
          itemsPerPage: 5,
          sortBy: ['id'],
          sortDesc: [true],
        },
        headers: [
          { text: 'ID', align: 'start', value: 'id', width: '7em' },
          { text: 'Название', value: 'name' },
          { text: 'Режим', value: 'emitModeText' },
          { text: 'Фильтр клиентов', value: 'client_filter_desc' },
          { text: 'Старт', value: 'startAtFormat', width: '9em' },
          { text: 'Окончание', value: 'finishAtFormat', width: '11em' },
          { text: 'Послед. запуск', value: 'lastEmitFormat', width: '9em' },
          { text: 'След. запуск', value: 'nextEmitFormat', width: '9em' },
          {
            text: 'Вкл/Выкл',
            value: 'active',
            width: '1em',
            filterable: false,
            sortable: false,
          },
          {
            text: ' ',
            value: 'actions',
            width: '1em',
            filterable: false,
            sortable: false,
          },
        ],
        expanded: [],
        editedHandler: null,
        editedHandlerBroadcaster: null,
        showHandlerDialog: false,
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        broadcasters: 'company/event_broadcasters/broadcasters',
      }),
      filtered_broadcasters () {
        if (this.search_comp) {
          return this.broadcasters.filter((item) => true)
        } else {
          return this.broadcasters
        }
      },
      search_comp () {
        return this.search ? this.search.trim().toLowerCase() : ''
      },
    },
    watch: {
      programId (v) {
        this.loadData()
      },
    },
    created () {
      this.loadData()
    },
    methods: {
      ...mapActions({
        GetList: 'company/event_broadcasters/GetList',
        SetActiveBroadcaster: 'company/event_broadcasters/SetActiveBroadcaster',
        RunBroadcaster: 'company/event_broadcasters/RunBroadcaster',
        DeleteBroadcaster: 'company/event_broadcasters/DeleteBroadcaster',
        getSegmentPickList: 'crm/segment/getPickList',
        getBonusResList: 'company/bonus_resources/GetList',
        getNotifyPickList: 'company/notifications/getPickList',
      }),
      createHandlerClick (broadcaster) {
        this.editedHandlerBroadcaster = broadcaster
        this.editedHandler = {
          // id:  this.$uuid()
          isNew: true,
          broadcaster_id: broadcaster.id,
          program_id: broadcaster.program_id,
          run_user_id: null,
          action_type: null,
          action_json: {},
          active: true,
        }

        this.showHandlerDialog = true
      },
      openHandlerClick (broadcaster, handler) {
        console.log('openHandlerClick', broadcaster, handler)

        this.editedHandler = Object.copy(handler)
        this.editedHandlerBroadcaster = broadcaster
        this.showHandlerDialog = true
      },
      onSaveHandler (updatedHandler) {
        const broadcaster = this.broadcasters.find(
          (x) => x.id === updatedHandler.broadcaster_id,
        )
        if (broadcaster) {
          const indexHandler = broadcaster.handlers.findIndex(
            (x) => x.id === updatedHandler.id,
          )
          if (indexHandler >= 0) {
            const handler = broadcaster.handlers[indexHandler]
            Vue.set(
              broadcaster.handlers,
              indexHandler,
              Object.assign({}, handler, updatedHandler),
            )
          } else {
            broadcaster.handlers.push(updatedHandler)
          }
        }
      },
      onDeleteHandler (handler) {
        const broadcaster = this.broadcasters.find(
          (x) => x.id === handler.broadcaster_id,
        )
        if (broadcaster) {
          const indexHandler = broadcaster.handlers.findIndex(
            (x) => x.id === handler.id,
          )
          if (indexHandler >= 0) broadcaster.handlers.splice(indexHandler, 1)
        }
      },
      openHelp () {
        console.log(this.$route.meta.help)

        this.$route.meta.help && window.open(this.$route.meta.help)
      },
      async loadData () {
        if (!this.programId) return
        try {
          this.GetListAction = true
          await this.GetList(this.programId)
          await this.getSegmentPickList(this.programId)
          await this.getBonusResList(this.programId)
          await this.getNotifyPickList(this.programId)
        } catch (error) {
          console.error(error)
        } finally {
          this.GetListAction = false
        }
      },
      onBroadcasterClick (item) {
        // this.selectedId = item.id
        // this.dialog = true
        const index = this.expanded.findIndex((x) => x.id === item.id)
        if (index >= 0) {
          this.expanded.splice(index, 1)
        } else {
          this.expanded.push(item)
        }
      },
      createBroadcasterClick () {
        this.$router.push({ name: 'EventBroadcasterMaster' })
      },
      async activeChange (item, active) {
        try {
          item.changeActiveAction = true
          // await this.$sleep()
          await this.SetActiveBroadcaster({
            id: item.id,
            active: active,
          })
        } catch (error) {
          item.active = !item.active
        } finally {
          item.changeActiveAction = false
        }
      },
      async deleteBroadcasterClick (item) {
        try {
          item.action = true
          // await this.$sleep()
          await this.DeleteBroadcaster(item.id)
        } catch (e) {
          console.error(e)
        } finally {
          item.action = false
        }
      },
      async runBroadcasterClick (item) {
        try {
          item.action = true
          // await this.$sleep()
          await this.RunBroadcaster(item.id)
        } catch (e) {
          console.error(e)
        } finally {
          item.action = false
        }
      },
      openBroadcasterLogClick (item) {
        this.selectedId = item.id
        this.dialog = true
      },
      async copyLinkToClipboard (item) {
         try {
          const queryParamStr = new URLSearchParams({
            pid: "from_cabinet_copy", // Media source name         
            c: 'activity_run', // required Campaign name
            is_retargeting: 'true', // required true-многоразовая, false - генерация события единожды
            af_force_deeplink: true,
            af_dp: `pluscardsapp://deeplink/program/activity/run?broadcaster_id=${item.id}&program_id=${item.program_id}`, // deeplink
        }).toString()

          await this.$copyText(`${this.$config.app.onelinkBaseUrl}?${queryParamStr}`)
          this.$notify({
            title: 'Активности',
            text: 'Ссылка успешно скопирована!',
            type: 'success',
          })
        } catch (error) {
          console.error(error)
        }
      },
      getItemActions (item) {
        return [
          {
            id: 1,
            icon: '$iconify_ion-document-outline',
            title: 'История работы',
            action: this.openBroadcasterLogClick,
            show: true,
          },
          {
            id: 2,
            icon: '$iconify_feather-play',
            title: 'Запустить',
            action: this.runBroadcasterClick,
            show:
              item.active &&
              item.emit_mode ===
              ProgramEventBroadcaster.EMIT_MODE_ENUM.MANUAL.id &&
              this.hasProgramPermission(
                'program-broadcaster-run',
                item.program_id,
              ),
          },
          {
            id: 3,
            icon: '$iconify_feather-copy',
            title: 'Скопировать ссылку',
            action: this.copyLinkToClipboard,  
            show: item.emit_mode === ProgramEventBroadcaster.EMIT_MODE_ENUM.ACCOUNT.id,          
          },
          {
            id: 4,
            icon: '$iconify_feather-edit',
            title: 'Редактировать',
            action: (item) => {
              this.$router.push({
                name: 'EventBroadcasterForm',
                params: {
                  id: item.id,
                },
              })
            },
            show: this.hasProgramPermission(
              'program-broadcaster-update',
              item.program_id,
            ),
          },          
          {
            id: 5,
            icon: '$iconify_feather-copy',
            title: 'Копировать',
            show: true,
            action: (item) => {
              this.$router.push({
                name: 'EventBroadcasterMaster',
                query: {
                  copyId: item.id,
                },
              })
            },
          },
          {
            id: 6,
            icon: '$iconify_feather-trash',
            title: 'Удалить',
            action: this.deleteBroadcasterClick,
            show:
              !item.deleted_at &&
              this.hasProgramPermission(
                'program-broadcaster-delete',
                item.program_id,
              ),
          },
          
        ].filter((x) => x.show)
      },
    },
  }
</script>
